import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { getSellerLink } from 'utils/helpersShared.utils'
import { isSharingPreviewRoute } from 'utils/sharing.utils'
import { isFunnelRoute } from 'shop/utils/funnels.utils'
import { getSearchParams } from 'utils/queryString.utils'

import { EMBED_TYPES } from 'constants/embeddableItemsShared.constants'

import { HeaderPreview } from 'shared/components/content-page/preview/blocks/header/HeaderPreview'
import { FooterPreview } from 'shared/components/content-page/preview/blocks/footer/FooterPreview'

import { useShopStores } from 'shop/hooks/use-store'

import './_custom-shop.scss'

export const HeaderFooterWrapper: React.FC<{
  pathname: string
  embedTypeQuery: string
  isProductPage: boolean
  isCheckoutPage: boolean
  isDealPage: boolean
  isCabinetPreview: boolean
  children: React.ReactNode
}> = observer(({ children, pathname, embedTypeQuery, isDealPage, isCabinetPreview, ...rest }) => {
  const {
    sellerStore,
    themeStore,
    courseViewsStore,
    ordersStore,
    funnelsStore,
    trackingCodesStore,
    shopThemeStore,
    contentPageStore,
    userStore,
  } = useShopStores()

  const embedType = getSearchParams().embed_type || embedTypeQuery
  const [isRunnedPageViewEvents, setIsRunnedPageViewEvents] = useState(false)

  // @ts-ignore
  const isProductPage = !!rest?.match?.params?.product_slug || rest.isProductPage
  const isOnPageEmbed = embedType === EMBED_TYPES.onPage

  const { headerHidden, header: headerDD, footer: footerDD } = shopThemeStore.displayData || {}
  const isThemePreview = isSharingPreviewRoute(pathname)

  const isFunnelPage = isFunnelRoute(pathname)
  const isFunnelHeaderLoading = !ordersStore.loading && !funnelsStore.loading
  const isHeaderReady = isFunnelPage ? isFunnelHeaderLoading : true
  const isPaymentPage = pathname.includes('payment')

  const showHeader =
    !isOnPageEmbed &&
    !isThemePreview &&
    courseViewsStore.membershipNavOpen &&
    !headerHidden &&
    isHeaderReady &&
    !isDealPage &&
    !isCabinetPreview
  const showFooter =
    !isOnPageEmbed &&
    !isThemePreview &&
    courseViewsStore.membershipNavOpen &&
    !themeStore.displayData?.footerFunnelPageHidden &&
    !isDealPage &&
    !isCabinetPreview

  useEffect(() => {
    setIsRunnedPageViewEvents(false)
  }, [pathname])

  useEffect(() => {
    if (!isCabinetPreview) {
      trackingCodesStore.applyCodesToPage(isProductPage)
    }
  }, [])

  useEffect(() => {
    const shouldRunPageViewEvents = isProductPage
      ? trackingCodesStore.trackingCodesLength > 1 && !isRunnedPageViewEvents
      : trackingCodesStore.trackingCodesLength && !isRunnedPageViewEvents

    if (shouldRunPageViewEvents && !isCabinetPreview) {
      trackingCodesStore.applyPageViewEvents(isProductPage)
      setIsRunnedPageViewEvents(true)
    }
  }, [trackingCodesStore.trackingCodesLength, isRunnedPageViewEvents])

  return (
    <Fragment>
      {showHeader && (
        <HeaderPreview
          block={{ content: headerDD || {} }}
          username={sellerStore.item.username}
          shopLink={getSellerLink(sellerStore.item)}
          shopThemeStore={shopThemeStore}
          contentPageStore={contentPageStore}
          userStore={userStore}
          sellerStore={sellerStore}
        />
      )}
      <div className='custom-shop__content'>{children}</div>
      {showFooter && (
        <FooterPreview
          block={{ content: footerDD || {} }}
          baseURL={getSellerLink(sellerStore.item)}
          isCheckoutPage={rest.isCheckoutPage || isPaymentPage}
          contentPageStore={contentPageStore}
          userStore={userStore}
        />
      )}
    </Fragment>
  )
})

HeaderFooterWrapper.displayName = 'HeaderFooterWrapper'
