import { Nullable } from 'types/helpers'

export interface Product {
  funnelsActivated?: boolean
  successUrl?: string
  id: number
  slug: string
  additionalInfo: Nullable<string>
  form: string
  cover: Nullable<string>
  name: string
  count?: any
}

export interface Invoice {
  id: number
  state: string
  token?: string
  number: string | number
  deadline: Nullable<string>
  total: number
  created: string
  createdAt: string
  downloadLink: string
  paymentLink: string
  url: string
  pricingPlans?: any
}

export interface PlanToChange {
  id: number
  name: string
  description: Nullable<string>
  currencyId: number
  form: string
}

export interface PlanPrefs {
  cancellationTermPeriod: Nullable<string>
  customIntervals: boolean
  customStartDay: string
  firstAmount: string
  firstInterval: string
  form: string
  minimumTerms: boolean
  nextAmount: string
  nextInterval: string
  pastDue: string
  paymentsCount: string
  price: Nullable<string>
  sofortSepa: Nullable<string>
  testPeriod: string
  testPeriodHideAmounts: boolean
}

export interface SellableItem {
  expired: boolean
  fileExists: boolean
  fileName: string
  id: number
  lessThen500Mb: boolean
  licenseKeys: []
  lifetimeHours: number
  link: string
  linkAll: string
  linkFile: string
  needStamping: boolean
  product: Product
  ticket: Nullable<[]>
  ticketDate: Nullable<string>
}

export interface Transfer {
  created: string
  createdAt: string
  currency_key: string
  form: string
  idForSeller: number
  inProgress: boolean
  invoiceLink: string
  provider: string
  providerData: {
    reference: string
    bankAccount: { bic: Nullable<string>; iban: Nullable<string>; owner: null }
    virtualIban: Nullable<string>
    clientSecret?: string
  }
  state: string
  successDate: string
  total: string | number
  withExtId: boolean
}

export interface MoneyHolder {
  cardProvider: string
  sofortProvider: string
  sepaProvider: string
  applePayProvider: string
  googlePayProvider: string
  p24Provider: string
  paypalProvider: string
  idealProvider: string
  klarnaProvider: string
  twintProvider: string
  stripePubKey: string
  elopageConnectPubKey: string
}

export enum CardBrands {
  visa = 'visa',
  mastercard = 'mastercard',
  cb = 'cb',
}

export interface PricingPlan {
  currencyId: number
  customIntervals: boolean
  description: Nullable<string>
  enabledPayMethods: string[]
  firstIntervalDate: string
  form: string
  id: number
  name: string
  nextIntervalDate: string
  paymentsCount: number
  prefs: PlanPrefs
  testPeriodDescription: Nullable<string>
  useNetPrice: boolean
}

export interface MinimumTerm {
  canCancel: boolean
  initialDuration: string
  extensionDuration: string
  endDate: string
  inExtensionPeriod: boolean
}

interface OrderRatePrice {
  id: number
  sellableId: number
  data: {
    cfgs: {
      isBusiness: boolean
      withVatNo: boolean
      mustPayVat: boolean
      calcsVatRate: Nullable<number>
      privateVatRate: number
      privateVatText: string
      vatBaseCountry: string
      businessVatRate: number
      businessVatText: string
      transferVatRate: number
    }
    rate: {
      net: number
      vat: number
      count: number
      gross: number
    }
    discount?: number
    fees?: number
    vatSum: number
    toPaySum: number
  }
}

export interface Rate {
  id: number
  rate: number
  scheduled: string
  successDate: string
  state: string
  total: number
  invoiceNumber: number
  invoiceUrl: string
  paymethod: string
  transfers: RateTransfer[]
  orderRatePrices: OrderRatePrice[]
}

interface RateTransfer {
  id: number
  total: number
  updatedAt: string
  idForSeller: number
  state: string
  transferableType: string
  form: string
}

interface Payer {
  id: number
  fullName: string
  email: string
  countryCode: string
  country: {
    code: string
  }
  address: string
}

interface PayerData {
  attendeeEmail: boolean
  formType: string
  formTypeForVat: string
  userAttributes: {
    email: string
    emailConfirmation: string
    timeZoneName: string
  }
  userProfileAttributes: {
    city: string
    company: string
    countryCode: string
    firstName: string
    lastName: string
    phone: string
    street: string
    streetNumber: string
    vatNo: string
    zip: string
    state?: string
  }
}

export interface Order {
  allowedPaymentMethods: string[]
  canChangePlan: boolean
  canContinue: boolean
  cancelAt: Nullable<string>
  company: Nullable<string>
  currencyId: number
  creditCard: {
    provider: string // TODO enum
    brand: string // TODO enum
  }
  providerData: {
    browserInfo?: any
  }
  currencyKey: string
  currentPlanId: number
  fullyPaid: boolean
  error?: any
  funnelId: Nullable<number>
  funnelName: Nullable<string>
  funnelNodeId: Nullable<number>
  hasPendingTransfers: boolean
  id: number
  invoices: Invoice[]
  keepAccess: boolean
  mandateUrl: Nullable<string>
  minimumTerms?: MinimumTerm[] // depends on expands
  moneyHolder: MoneyHolder
  nextChargeAmount: number
  nextChargeDate: string
  orders?: any[]
  owner: {
    id: number
    fullName: string
    email: string
  }
  paidCount: number
  payer: Payer
  payerData: PayerData
  paymentForm: string
  paymentState: string
  paypalWithImmediateAccess: boolean
  periodType: string
  plansToChange: PlanToChange[]
  product: Product
  products: {
    id: number
    licenseKeys: string[]
    name: string
    shortDescription: Nullable<string>
    slug: string
    cover: Nullable<string>
  }[]
  protectionUrl: string
  provider: string
  paypalMerchantId: string
  rates: Rate[]
  relatedOrders: string[]
  reseller: Record<string, unknown> // TODO: add types
  sellableWithPeriod: { planPrefs: PlanPrefs; pricingPlan: PricingPlan }
  sellables: {
    id: number
    product: Product
    sellableItems: SellableItem[]
  }[]
  sendEmail: boolean
  sepaWithImmediateAccess: boolean
  sofortWithImmediateAccess: boolean
  testPeriodPassed: boolean
  token: string
  totalAmount: string
  tracked: boolean
  transfers: Transfer[]
  withCustomStart: boolean
  withTestPeriod: boolean
  withReseller: boolean
  redirectLink: string
  paymentBlocked?: boolean
  pricingPlan?: any
  ticketUpgradeConnectedOrder?: Nullable<number>
}

export interface Details extends Order {
  deal?: any
  blocked?: boolean
  params: {
    hide_next_charge_date?: string
    hide_payments_count?: string
    hide_period_type?: string
    hide_paymethod?: string
    hide_payment_state?: string
  }
  payerCanEditTicketAttendees?: boolean
  ticketAttendeeCount?: number
}

export interface Paymethods {
  form: string
  creditCard: string
  iban: string
  valid: boolean
}

export interface Popups {
  changePlan: boolean
  changeMethod: boolean
  changePlanAutopay: boolean
  postsellPayment: boolean
  autopaySecondStep: boolean
  changeAttendeeDetails: boolean
}
