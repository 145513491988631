import React from 'react'
/* eslint-disable */
import { ReactPayPalScriptOptions } from '@paypal/react-paypal-js/dist/types/types/scriptProviderTypes'

import { PayPalScriptProvider } from '@paypal/react-paypal-js'
/* eslint-enable */

interface Props {
  options: ReactPayPalScriptOptions
  children: React.ReactNode
}

export const AblefyPaypalProvider = React.memo(({ options, children }: Props) => {
  if (!options.clientId) {
    return <>{children}</>
  }

  return (
    <PayPalScriptProvider options={options} deferLoading={false}>
      {children}
    </PayPalScriptProvider>
  )
})
