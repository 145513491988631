import { Nullable, ProductType } from 'types/helpers'

interface CheckoutSummaryRecurringPaymentSum {
  date: Nullable<string>
  interval: string
  total: number
  totalDiscounted: number
  totalDiscountedVat: number
  totalVat: number
}

interface CheckoutSummarySellable {
  dueToday: number
  dueTodayDiscounted: number
  dueTodayVat: number
  dueTodayDiscountedVat: number
  endDateOfTheTrialPeriod: Nullable<string>
  firstPaymentStartDay: Nullable<string>
  subtotal: number
  subtotalDiscounted: number
  subtotalVat: number
  subtotalDiscountedVat: number
  subtotalPaymentsCount: number
  additionalFeePrice: number
  additionalFeeName: string
  additionalFeeIncluded: boolean
  coupon: {
    value: Nullable<string>
    valueForm: Nullable<string>
  }
  recurringPayments: {
    amount: number
    date: Nullable<string>
    discountedAmount: number
    discountedVat: number
    interval: string
    vat: number
  }[]
  priceCount: number
  productName: string
  productForm: ProductType
  vatPercentage: number
  isLimitedSubscription: boolean
  isInstallment: boolean
  isSubscription: boolean
  withTrialPeriod: boolean
  withCoupon: boolean
  withCustomStartDay: boolean
}

export interface CheckoutSummary {
  sellables: CheckoutSummarySellable[]
  dueTodaySum: number
  dueTodayDiscountedSum: number
  totalSum: number
  totalDiscountedSum: number
  recurringPaymentSums: CheckoutSummaryRecurringPaymentSum[]
  couponSummary: {
    productCount: number
    value: Nullable<string>
    valueForm: Nullable<string>
  }[]
  currencyId: number
  additionalFeeIncluded: boolean
  vatIncluded: boolean
  totalDiscountedVatSum: number
  isReverseCharge: boolean
  withCoupon: boolean
  vatCountry: string
  reverseChargeVatPercentage: number
  vatText: string
}
