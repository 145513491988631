import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'

import { loadScript } from 'shop/utils/trackingCodes.utils'
import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { getElopageConfig } from 'utils/elopageConfig.utils'

interface Props {
  csId: string
  staging: boolean
  tracifyEventShouldBeFired: boolean
  cleareTracifyQEvent: () => void
  isProductPage: boolean
}

interface TracifyInstance {
  captureProductView: () => void
  capturePageView: () => void
  captureAddToCart: () => void
}

export const Tracify: React.FC<Props> = observer(
  ({ csId, staging, tracifyEventShouldBeFired, cleareTracifyQEvent, isProductPage }): React.ReactElement => {
    const [inited, setInited] = useState(false)
    const { pathname: path } = useNextRouter()
    const tracifyInstance = useRef<TracifyInstance>()

    const init = async () => {
      if (!csId) {
        return
      }

      const env = getElopageConfig('env')
      const src =
        staging || env === 'development'
          ? 'https://devscripting.tracify.ai/api.v1.js'
          : 'https://scripting.tracify.ai/api.v1.js'

      await loadScript(src)

      if (window.Tracify) {
        tracifyInstance.current = window.Tracify({ csid: csId, fingerPrint: true })
        setInited(true)
      }
    }

    useEffect(() => {
      init()
    }, [])

    useEffect(() => {
      if (!csId || !inited) {
        return
      }

      if (isProductPage) {
        tracifyInstance.current.captureProductView()
      } else {
        tracifyInstance.current.capturePageView()
      }
    }, [inited, path])

    useEffect(() => {
      if (!csId || !inited) {
        return
      }

      if (tracifyEventShouldBeFired) {
        tracifyInstance.current.captureAddToCart()
      }

      return () => {
        cleareTracifyQEvent()
      }
    }, [inited, tracifyEventShouldBeFired])

    return null
  }
)
