import type { Stripe, StripeElements, StripeExpressCheckoutElement } from '@stripe/stripe-js'

export type {
  Stripe,
  StripeElement,
  StripeElements,
  StripeExpressCheckoutElement,
  StripeP24BankElement,
  StripeIdealBankElement,
  StripeElementsOptionsMode,
  PaymentIntentResult,
  BillingDetails,
  ShippingAddress,
  StripeExpressCheckoutElementShippingAddressChangeEvent,
} from '@stripe/stripe-js'

import { Nullable } from 'types/helpers'

export interface StripePaymentParams {
  clientSecret: Nullable<string>
  redirectionUrl?: Nullable<string>
  payerFullName: Nullable<string>
  payerCountryCode: Nullable<string>
  payerEmail: Nullable<string>
  amount?: number
  redirectLink?: string
  currency?: string
}

export interface StripeMessageParams {
  appearance?: Record<string, any>
}

export interface KlarnaFormSettings {
  fields: {
    billingDetails: 'never'
  }
}

export interface ClickSubmitData {
  stripeClient: Stripe
  elements: StripeElements
  expressElement: StripeExpressCheckoutElement
  showPopup: () => void
  recaptchaValue?: string
}
