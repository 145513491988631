import type { AppProps } from 'next/app'
import React, { useEffect } from 'react'
import Head from 'next/head'
import { Provider as MobxProvider, observer } from 'mobx-react'
import { ToastContainer } from 'react-toastify'
import classNames from 'classnames'

import 'libs/i18n/shop' // order import is important (I18n should be always on the top)

import { I18nProvider } from '@elo-kit/components/i18n/i18n'

import { COOKIES_CONSENTS_LOCATION } from '@elo-kit/constants/cookiesConsents.constants'
import { CUSTOM_CSS_CONFIG_FIELD_NAME, THEME_FORMS } from 'constants/themes.constants'
import { EMBED_TYPES } from 'constants/embeddableItemsShared.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { APP_NAMES } from 'constants/general.constants'

import { profile } from 'utils/profileHelper.utils'
import { useReportWebVitals } from 'next/web-vitals'
import { webVitalsReporter } from 'shop/utils/web-vitals-reporter.utils'

import { AutopayHandlerModal } from 'shared/components/purchase-methods/AutopayHandlerModal'
import { CookiesConsentFloatingBtnStyled } from '@elo-kit/components/cookies-consent-widget/cookies-consent-floating-btn/CookiesConsentFloatingBtn'
import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'
import { ClientOnly } from 'shared/components/ClientOnly'
import InnerHtml from 'ui/InnerHTML'

import CookiesConsentWidget from 'shared/components/cookies-consent-widget/CookiesConsentWidget'

import { useStore, useShopStores } from 'shop/hooks/use-store'
import { useCookiebot } from 'shop/hooks/use-cookiebot'
import { useChat } from 'shop/hooks/use-chat'
import { useLogger } from 'shop/hooks/use-logger'
// import { useCleanupJSS } from 'shop/hooks/use-jss'
import { fetchCustomSellerFonts } from 'shop/utils/fonts-preload.utils'
import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { getShopMetaTags } from 'shop/utils/getMetaTags.utils'

import { HeaderFooterWrapper } from 'shop/components/HeaderFooterWrapper'
import { Tracify } from 'shop/components/TracifyNext'
import { ProgressBar } from 'shop/components/ProgressBar'
import { BootstrappedStatsigProvider } from 'shop/components/experiments/BootstrappedStatsigProvider'
import { useRedirectionCookies } from 'shop/hooks/use-redirection-cookies'
import { AblefyPaypalProvider } from 'shop/components/paypal-provider/ablefy-paypal-provider'
import { DEFAULT_CURRENCY } from 'constants/currencies.constants'

/* eslint-disable */
import { ReactPayPalScriptOptions } from '@paypal/react-paypal-js/dist/types/types/scriptProviderTypes'
/* eslint-enable */
import { PAYPAL_ENABLED_FUNDINGS, PAYPAL_SDK_BASE_CONFIG } from 'constants/paymentSetting.constants'

import '../../styles/react/_ui_components.scss'
import '../../styles/shop.scss'

interface AppPageParams {
  embed_type: string
  transparent: string
  cabinet_preview: string
}

profile.setProfileType(ACTIVE_PROFILES.shop)

const App = observer(function App({
  children,
  locale,
  isProductPage,
  isCheckoutPage,
  isDealPage,
  ssrApiUrl,
  metaData = {},
}) {
  const {
    trackingCodesStore,
    sellerStore,
    cookiesConsentsStore,
    cookiesConsentCategoriesStore,
    ordersStore,
    sellerFontsStore,
    userStore,
    paymentStore,
    affiliatesStore,
    shopThemeStore,
    contentPageStore,
  } = useShopStores()

  useEffect(() => {
    affiliatesStore.setCampaignID()
  }, [])

  const { paymentPageTemplateId } = paymentStore.store?.props || {}

  const { pathname, params } = useNextRouter<AppPageParams>()
  const {
    handleCookiebotRenew,
    handleCookiesSubmit,
    showCookieConsent,
    withCookiebot,
    showFloatingCookiebotConsent,
    isCustomCssAppActive,
  } = useCookiebot({ trackingCodesStore, sellerStore, cookiesConsentsStore, cookiesConsentCategoriesStore })

  const { chatBody } = useChat({ sellerStore })

  const customCss = shopThemeStore.shopTheme?.prefs || {}
  const isOnPageEmbed = params.embed_type === EMBED_TYPES.onPage

  const customShopClasses = classNames(`custom-shop ${locale}`, {
    'iframed-custom-shop': isOnPageEmbed,
    'iframed-transparent': params.transparent && params.transparent !== 'false',
  })

  const shouldRenderPostsellPaymentModal = ordersStore.popups.postsellPayment
  const isNamottoShop = shopThemeStore.shopTheme.form === THEME_FORMS.namotto
  const legalLinks = shopThemeStore.shopTheme.prefs.footer?.menuList?.filter((item) => item?.default)

  return (
    <>
      <ClientOnly>
        <ProgressBar color={shopThemeStore.shopTheme?.prefs?.header?.backgroundColor} />
      </ClientOnly>
      <Head>
        {getShopMetaTags(metaData)}
        {!isNamottoShop && <link rel='manifest' href='/manifest.json' />}
        {sellerFontsStore.list.length > 0 && fetchCustomSellerFonts(sellerFontsStore.list)}
        {withCookiebot && (
          <script
            id='Cookiebot'
            src='https://consent.cookiebot.com/uc.js'
            data-cbid={sellerStore.item.cookiebotId}
            type='text/javascript'
            data-blockingmode={sellerStore.item.cookiebotMode}
            data-culture={locale}
          ></script>
        )}
      </Head>
      <div className={customShopClasses}>
        <HeaderFooterWrapper
          embedTypeQuery={params.embed_type}
          pathname={pathname}
          isProductPage={isProductPage}
          isCheckoutPage={isCheckoutPage}
          isDealPage={isDealPage}
          isCabinetPreview={!!params.cabinet_preview}
        >
          {children}
        </HeaderFooterWrapper>
      </div>
      {isCustomCssAppActive && customCss[CUSTOM_CSS_CONFIG_FIELD_NAME] && (
        <style
          dangerouslySetInnerHTML={{
            __html: customCss[CUSTOM_CSS_CONFIG_FIELD_NAME],
          }}
        />
      )}

      {!params.cabinet_preview && (
        <>
          <ClientOnly>
            <AdminMode userEmail={userStore.item.email} />
          </ClientOnly>

          <ClientOnly>
            <ApiBranch showSrrUrl ssrApiUrl={ssrApiUrl} />
          </ClientOnly>

          <ClientOnly>{chatBody && <InnerHtml html={chatBody} />}</ClientOnly>

          <ClientOnly>
            {showCookieConsent && !paymentPageTemplateId && (
              <CookiesConsentWidget
                location={COOKIES_CONSENTS_LOCATION.shop}
                username={sellerStore.item.username}
                isPowerSeller={sellerStore.item.powerSeller}
                theme={shopThemeStore.shopTheme.prefs.cookies}
                form={sellerStore.item.consentForm}
                onSubmit={handleCookiesSubmit}
                legalLinks={legalLinks}
                cookiesConsentCategoriesStore={cookiesConsentCategoriesStore}
                cookiesConsentsStore={cookiesConsentsStore}
                sellerStore={sellerStore}
                trackingCodesStore={trackingCodesStore}
                isCheckoutPage={isCheckoutPage}
                themePages={contentPageStore.themePages}
              />
            )}
            {showFloatingCookiebotConsent && (
              <CookiesConsentFloatingBtnStyled
                theme={shopThemeStore.shopTheme.prefs.cookies}
                // @ts-ignore
                onBtnClick={handleCookiebotRenew}
              />
            )}
            {shouldRenderPostsellPaymentModal && (
              <AutopayHandlerModal
                isOpen={shouldRenderPostsellPaymentModal}
                toggle={() => ordersStore.togglePopup('postsellPayment')}
                paymentForm={ordersStore?.data?.paymentForm || ordersStore?.lastOrder.paymentForm}
                stripePubKey={ordersStore?.data?.moneyHolder.stripePubKey}
                elopageConnectPubKey={ordersStore?.data.moneyHolder.elopageConnectPubKey}
                paymentInfo={ordersStore.stripePaymentParams}
                title={I18n.t('react.shop.funnel.with_auto_payment.title')}
                message={I18n.t('react.shop.funnel.with_auto_payment.description')}
                getBillingDetails={ordersStore.getBillingDetails}
                orderProvider={ordersStore?.data?.provider}
                providers={{
                  cardProvider: ordersStore?.data?.moneyHolder?.cardProvider,
                  sofortProvider: ordersStore?.data?.moneyHolder?.sofortProvider,
                  sepaProvider: ordersStore?.data?.moneyHolder?.sepaProvider,
                  applePayProvider: ordersStore?.data?.moneyHolder?.applePayProvider,
                  googlePayProvider: ordersStore?.data?.moneyHolder?.googlePayProvider,
                  p24Provider: ordersStore?.data?.moneyHolder?.p24Provider,
                  klarnaProvider: ordersStore?.data?.moneyHolder?.klarnaProvider,
                }}
                payOptions={{
                  amount: ordersStore.stripePaymentParams?.amount,
                  currency: ordersStore.stripePaymentParams?.currency,
                }}
              />
            )}
          </ClientOnly>

          <Tracify
            csId={trackingCodesStore.tracifyConfig.csId}
            staging={trackingCodesStore.tracifyConfig.staging}
            tracifyEventShouldBeFired={trackingCodesStore.tracifyEventShouldBeFired}
            cleareTracifyQEvent={trackingCodesStore.cleareTracifyQEvent}
            isProductPage={isProductPage && !isCheckoutPage}
          />
        </>
      )}
      <ToastContainer />
    </>
  )
})

export default function MyApp(props: AppProps & any) {
  // TODO: SSR - declare of AppProps instead of any
  const { Component, pageProps } = props

  const stores = useStore(pageProps)
  const router = useNextRouter()

  const paypalScriptOptions: ReactPayPalScriptOptions = {
    clientId: stores.sellerStore?.item?.paypalClientId,
    enableFunding: PAYPAL_ENABLED_FUNDINGS,
    currency: DEFAULT_CURRENCY.toUpperCase(),
    ...PAYPAL_SDK_BASE_CONFIG,
  }

  useLogger(pageProps.serverSideLogs)

  // useCleanupJSS() // TODO: check - why this break loyaut on CSR

  useReportWebVitals((metric) => {
    webVitalsReporter.report({
      metric,
      sellerId: String(stores.sellerStore.item.id),
      api: stores.paymentStore.kibanaApi,
    })
  })

  stores.i18nStore.init({ router: '', appName: APP_NAMES.shop, locale: pageProps.locale })
  stores.i18nStore.hydrate({ locale: pageProps.locale, translations: pageProps.translations })

  // TODO: delete this hook call after end of the redirection phase
  // this hook is used to store cookies and local storage after the redirection from the old domain
  useRedirectionCookies(router.params as { cdata?: string; ldata?: string })

  if (pageProps.isErrorPage) {
    return <Component {...pageProps} />
  }

  if (pageProps.redirectPage) {
    return (
      <MobxProvider {...stores}>
        <Component {...pageProps} />
      </MobxProvider>
    )
  }

  if (pageProps.experiments?.isActive) {
    return (
      <MobxProvider {...stores}>
        <I18nProvider locale={pageProps.locale}>
          <AblefyPaypalProvider options={paypalScriptOptions}>
            <BootstrappedStatsigProvider
              clientSdkKey={pageProps.experiments.clientSdkKey}
              values={pageProps.experiments.serverConfig}
              user={pageProps.experiments.user}
            >
              <App
                locale={pageProps.locale}
                isProductPage={pageProps.isProductPage}
                isCheckoutPage={pageProps.isCheckoutPage}
                ssrApiUrl={pageProps.ssrApiUrl}
                metaData={pageProps.metaData}
                isDealPage={pageProps.isDealPage}
              >
                <Component {...pageProps} />
              </App>
            </BootstrappedStatsigProvider>
          </AblefyPaypalProvider>
        </I18nProvider>
      </MobxProvider>
    )
  }

  return (
    <MobxProvider {...stores}>
      <I18nProvider locale={pageProps.locale}>
        <AblefyPaypalProvider options={paypalScriptOptions}>
          <App
            locale={pageProps.locale}
            isProductPage={pageProps.isProductPage}
            isCheckoutPage={pageProps.isCheckoutPage}
            ssrApiUrl={pageProps.ssrApiUrl}
            metaData={pageProps.metaData}
            isDealPage={pageProps.isDealPage}
          >
            <Component {...pageProps} />
          </App>
        </AblefyPaypalProvider>
      </I18nProvider>
    </MobxProvider>
  )
}
